import React, {Component} from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import MultipleInputs from '../components/form/multiple-input'
import Select from '../components/form/select'
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add'
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from "@material-ui/core/IconButton";
import { stitchURL } from '../assets/config.json'

class Rig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies : [],
      rigs : []
    };

    this.axios = axios.create({
      baseURL: stitchURL,
      timeout: 1000,
      headers: {}
    });

    this.inputChange = this.inputChange.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  inputChange(value) {
    this.setState(value)
  }

  postData(query){
    this.axios.post('/POST?collection=rig', query)
    .then(async function (response) {
      await this.loadData()

    })
    .catch(function (error) {

    });
  }

  async componentDidMount() {
    await this.loadData()
  }

  async loadData() {
    await this.axios.get('/GET?collection=company').then(response => {
      this.setState({companies : response.data.map(company => {return {value : company._id, label : company.name}})})});
    await this.axios.get('/GET?collection=rig').then(response => {
      this.setState({rigs : response.data.map(rig => {return { _id: rig._id, name : rig.name.join(' - '), company_id : rig.company_id}})})});
    
  }
  render() {

    const columns = [
      {
        name: "_id",
        options: {
          filter: false,
          sort: false,
        }
      }, 
      {
       name: "name",
       label: "Name",
       options: {
        filter: true,
        sort: true,
       }
      },
       {
        name: "company",
        label: "Company",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "Actions",
        label: "Actions",
        options: {
         filter: false,
         sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
            <Delete style={{marginLeft : '5px'}}></Delete><Edit style={{marginRight : '5px'}}></Edit>
            </div>
          );
       }}},   
     ];
    
    const options = {
      selectableRows: 'none',
      customToolbar: () => {
        return (
        <IconButton>
          <Add />
        </IconButton>);
      }
    };
    return (
      <div>
        <MUIDataTable
            title={"Rigs"}
            data={this.state.rigs.map(rig => {return {
              _id: rig._id,
              name : rig.name,
              company: this.state.companies.filter(company => {return company.value === rig.company_id})[0].label
            }})}
            columns={columns}
            options={options}
          />
        <Typography variant="h4" style={{ color: "white", padding: "8px" }}>
          Rig
        </Typography>
        <FormGroup>
        <MultipleInputs name="Rig Name" inputKey="name" inputChange={this.inputChange} />
        <Select name="Company" inputKey="company_id" inputChange={this.inputChange} data={this.state.companies} />
        <br />
        <Button variant="contained" color="secondary" onClick={() => {this.postData({ name: this.state.name, company_id: this.state.company_id})}}>Post</Button>
        </FormGroup>        
      </div>
    );
  }
}

export default Rig;