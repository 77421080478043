import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import blueGrey from "@material-ui/core/colors/blueGrey";

class Comments extends React.Component {
  
  render() {
    return (
      <List
        subheader={
          <ListSubheader>
            <Typography variant="h4" style={{ color: "white", padding: "10px" }}>
              Extracted Values
            </Typography>
          </ListSubheader>
        }
        style={{
          position: "absolute",
          textAlign: "center",
          top: "50%",
          left: "50%",
          width: "40%",
          transform: "translate(-50%, -50%)",
          backgroundColor: blueGrey[700] ,
        }}
      >
        <Divider style={{ backgroundColor: blueGrey[700] }} component="li" />
        <Table style={{backgroundColor: blueGrey[700] }}>
        <TableBody>
          {Object.keys(this.props.data).map((e, i)=> (
            <TableRow key={e}>
              <TableCell component="th" scope="row" style={{textTransform: "capitalize",  color: "white", width: '40%'}}>
                {e.split('_').join(' ')}
              </TableCell>
          {e === 'body' ? <div dangerouslySetInnerHTML={{ __html: this.props.data[e]}} /> : this.props.data[e] ? <TableCell align="left" style={{textTransform: "capitalize",  color: "white"}}>{this.props.data[e].toString()}</TableCell> : <TableCell align="left" style={{textTransform: "capitalize",  color: "red"}}>Could Not Be Parsed</TableCell> }
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </List>
    );
  }
}

export default Comments;
