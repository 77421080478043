import React from 'react'

export default function failIcon(props) {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" fill={props.fill} x="0px" y="0px"
	 width="30px" height="30px" viewBox="0 0 230.122 230.122">
<g>
	<g>
		<path d="M205.354,56.273c-2.236-11.803-6.131-23.609-13.348-33.352c-6.232-8.415-14.903-14.877-24.616-18.739
			c-9.708-3.86-20.875-5.39-31.152-3.128c-6.057,1.333-11.715,4.029-16.511,7.965c-4.509,3.701-8.262,8.222-11.495,13.056
			c-6.754,10.098-11.545,21.356-16.317,32.479C86.97,66.082,81.897,77.605,75.195,88.246c-3.317,5.296-7.241,10.308-11.513,14.865
			c-3.989,4.255-8.487,7.949-13.347,11.172c0.187-0.125,0.208-0.137-0.018,0.012c-6.578,4.355-12.911,9.268-18.191,15.16
			c-5.069,5.656-9.449,12.651-9.707,20.457c-0.132,4.006,0.899,7.941,2.761,11.477c2.618,4.971,6.967,8.757,11.784,11.534
			c5.248,3.024,11.043,4.829,16.949,6.033c1.074,0.219,2.082,0.473,3.092,0.869c0.944,0.37,1.972,0.758,2.767,1.395
			c0.868,0.695,1.717,1.38,2.403,2.263c0.85,1.094,1.599,2.276,2.184,3.532c2.387,5.127,3.908,10.729,7.805,15.007
			c1.741,1.911,3.831,3.25,6.301,4.006c2.55,0.781,5.214,1.117,7.877,1.127c5.358,0.021,10.619-1.19,15.854-2.184
			c2.646-0.502,5.304-0.947,7.99-1.183c1.115-0.099,2.206-0.173,3.317-0.06c0.452,0.046,0.916,0.048,1.356,0.16
			c1.84,0.467,2.98,1.891,3.495,2.646c1.203,1.764,1.942,3.791,2.72,5.767c1.368,3.474,2.631,7.057,4.649,10.221
			c1.446,2.268,3.288,4.117,5.633,5.446c2.103,1.19,4.438,1.909,6.847,2.097c5.375,0.42,10.791-1.515,15.31-4.306
			c5.082-3.14,9.358-7.461,13.108-12.074c3.73-4.593,7.019-9.556,10.045-14.636c6.187-10.385,11.146-21.464,15.263-32.818
			c4.227-11.663,7.724-23.603,10.346-35.728c2.632-12.171,4.388-24.565,5.116-36.999C208.123,81.063,207.678,68.54,205.354,56.273z
			 M144.92,49.351c-1.131,4.744-4.225,9.218-8.48,11.666c-4.045,2.326-9.506,2.863-13.803,0.906c-3.887-1.77-6.223-5.63-5.821-9.909
			c0.438-4.679,3.695-8.731,7.55-11.189c1.4-0.893,2.915-1.603,4.496-2.109c-1.587,0.508-2.214,0.708-2.27,0.726
			c0.072-0.023,1.173-0.375,2.273-0.727c-0.001,0-0.002,0.001-0.004,0.001c0.002-0.001,0.004-0.001,0.006-0.002
			c3.947-1.262,1.979-0.632,0.006-0.001c3.946-1.262,9.035-2.223,12.84-0.01C145.422,40.86,145.824,45.552,144.92,49.351z
			 M183.965,66.565c-1.019,4.088-3.825,7.984-8.103,8.998c-4.403,1.043-8.707-1.325-11.405-4.705
			c-3.281-4.105-4.313-9.351-3.883-14.496c0.336-4,1.735-9.002,6.004-10.407c0.978-0.322,2.019-0.416,3.043-0.345
			c-0.691-0.048-1.379-0.096-1.705-0.119c0.256,0.018,0.783,0.054,1.709,0.119c0.33,0.023,0.6,0.042,0.834,0.058
			c0.274,0.019,0.521,0.036,0.707,0.049c-0.166-0.012-0.404-0.028-0.707-0.049c-0.26-0.018-0.543-0.038-0.83-0.058
			c4.524,0.317,8.688,3.391,11.357,6.896C184.072,56.558,185.199,61.605,183.965,66.565z"/>
		<path d="M50.319,114.295c0.006-0.004,0.012-0.008,0.018-0.012C48.872,115.253,37.054,123.08,50.319,114.295z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

    )
}
