import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListSubheader from "@material-ui/core/ListSubheader";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import SendIcon from "@material-ui/icons/Send";
import TimeAgo from "react-timeago";
import { stitchURL } from '../../assets/config.json'
import axios from "axios";


class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = { commentInput: '' };
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  render() {
    return (
      <List
        subheader={
          <ListSubheader>
            <Typography variant="h4" style={{ color: "white", padding: "8px" }}>
              Comments
            </Typography>
          </ListSubheader>
        }
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          maxHeight: "75%",
          overflow: 'auto',
          transform: "translate(-50%, -50%)"
        }}
      >
        <Divider style={{ backgroundColor: "white" }} component="li" />
        {this.props.data.comments.map(comment => (
          <div>
            <Divider style={{ backgroundColor: "white" }} component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar src={comment.picture} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h5" style={{ color: "#fff" }}>
                    {comment.name}
                  </Typography>
                }
                secondary={
                  <div>
                    <Typography style={{ color: comment.type === "important" ? "red" : "#fff" }}>
                      {comment.message}
                    </Typography>
                    <Typography variant="p" style={{ color: "#fff" }}>
                      <TimeAgo date={comment.date} />
                    </Typography>
                  </div>
                }
              />
            </ListItem>
          </div>
        ))}
        <Paper style={{ display: "flex", alignItems: "center" }}>
          <CommentIcon style={{ padding: 10 }} />
          <InputBase
            style={{ marginLeft: 8, flex: 1 }}
            placeholder="Add a Comment"
            onChange={e => {
              this.setState({commentInput : e.target.value});
            }}
          />
          <IconButton style={{ padding: 10 }}>
            <SendIcon onClick={async () => {
                var validation = this.props.data;
                validation.comments.push({type: 'comment', name: this.getCookie('name'), picture: this.getCookie('picture'), message: this.state.commentInput, date: new Date()})
                 await axios.post( stitchURL + '/UPDATE?collection=parsed_report',{ id: this.props.id, key: 'validation', query: validation });
                 await window.location.reload();

            }}/>
          </IconButton>
        </Paper>
      </List>
    );
  }
}

export default Comments;
