import React, { Fragment } from 'react';
import MUIDataTable from "mui-datatables";
import PDFIcon from '@material-ui/icons/PictureAsPdf'
import Modal from '@material-ui/core/Modal';
import LinkIcon from '@material-ui/icons/Link'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import SuccessIcon from '../icons/successIcon';
import ManualVerifyIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '../icons/failIcon';
import WarningIcon from '@material-ui/icons/Warning';
import SpudderIcon from '@material-ui/icons/Opacity';
import AddIcon from '@material-ui/icons/Add';
import TextFieldIcon from '@material-ui/icons/TextFields';
import EmailIcon from '@material-ui/icons/Email';
import CommentIcon from '@material-ui/icons/Comment';
import BlockIcon from '@material-ui/icons/Block';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import InProgressIcon from '@material-ui/icons/Autorenew';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import orange from "@material-ui/core/colors/orange";
import Badge from '@material-ui/core/Badge';
import Comments from '../comments/comments'
import ExtractedData from '../extracted-data/surveys-extracted-data'
import Avatar from '@material-ui/core/Avatar';
import { stitchURL, corvaURL } from '../../assets/config.json'
import Tooltip from '@material-ui/core/Tooltip';
import TimeAgo from 'react-timeago'
import axios from "axios";
import ReRun from "@material-ui/icons/TouchApp";
import ForceRerun from "@material-ui/icons/Gavel";

class Table extends React.Component {

    triggerDialog(boolean, data, id){
        this.setState({dialogOpen : boolean, dialogData : data, recordID : id})
    }

    triggerComments(boolean, data, id){
      this.setState({commentModalOpen : boolean, commentModalData : data, recordID : id})
  }

  triggerParsedData(boolean, data){
    this.setState({parsedModalOpen : boolean, parsedModalData : data})
}

getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

getColumns(nameFilterList)  {
  return  [
  {
    name: "_id",
    options: {
      display: false,
      filter: false,
      sort: false,
    }
  },  
  {
    name: "date",
    label: "Date",
    options: {
     filter: false,
     sort: true,
     sortDirection: 'desc',
     customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Fragment><TimeAgo date={new Date(value  * 1000)} /></Fragment>
      );
    }}
   },
   {
    name: "company.name",
    label: "Company Name",
    options: {
     filter: true,
     sort: false,
     customBodyRender: (value, tableMeta, updateValue) => {
      return (
        value ? <Fragment>{value}</Fragment> : <p style={{color : 'rgb(0, 0, 0, 0.5)'}}>Company Not Found</p>
      );
    }
    }
   },
  {
   name: "rig.name",
   label: "Rig Name",
   options: {
    filter: true,
    sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        value ? <Fragment>{value.join(' - ')}</Fragment> : <p style={{color : 'rgb(0, 0, 0, 0.5)'}}>Rig Not Found</p>
      );
    }
   }
  },
   {
    name: "validation",
    label: "Status",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        value.status === 'fail' ? (
          <Tooltip title={value.errorMessages}>
            <div onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}}>
            <ErrorIcon fill='rgb(255, 0, 0)' />
            </div>
          </Tooltip>
        ) : value.status === 'success' ? (
          <Tooltip title='Success'>
          <SuccessIcon fill='rgb(0, 128, 0)' />
          </Tooltip>
        ) : value.status === 'manual-verify' ? (
          <Tooltip title='Manually Verifed'>
          <ManualVerifyIcon style={{color : 'rgb(3, 155, 229)'}}  onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}}></ManualVerifyIcon>
          </Tooltip>
        ) : value.status === 'warning' ? (
          <Tooltip title='Warning'>
          <WarningIcon style={{color : 'rgb(255, 165, 0)'}}  onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}}></WarningIcon>
          </Tooltip>
        ) : value.status === 'not-found' ? (
          <Tooltip title='Not Found'>
          <BlockIcon style={{color : '#73BFB8'}}  onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}} />     
          </Tooltip>
        ) : value.status === 'in-progress' ? (
          <Tooltip title='In Progress'>
          <InProgressIcon style={{color : '#333333'}} />     
          </Tooltip>
        ) : value.status === 'new-well' ? (
          <Tooltip title='New Well'>
          <AddIcon style={{color : '#7D5BA6'}}  onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}} />     
          </Tooltip>
        )  : value.status === 'spudder-rig' ? (
          <Tooltip title='Spudder Rig'>
          <SpudderIcon style={{color : '#FF8C42'}}  onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}} />     
          </Tooltip>
        ) : <Avatar style={{backgroundColor : 'white', color : 'black'}}  onClick={() => {this.triggerDialog(true,{title : 'Status', data : value, key : 'status'},tableMeta.rowData[0])}}></Avatar>
      );
    }}},
   {
    name: "document",
    label: "PDF",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <PDFIcon onClick={() => {
          if(value !== undefined)
            window.open( stitchURL+ '/FILE?user_id=' + this.getCookie('user_id') + '&collection=document&key=' + value.key, '_blank');
        }} />
      );
   }}},
   {
    name: "document",
    label: "Download",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <DownloadIcon onClick={() => {
          if(value !== undefined)
            window.open( stitchURL+ '/FILE?user_id=' + this.getCookie('user_id') + '&collection=download&key=' + value.key, '_blank');
        }} />
      );
   }}},
   {
    name: "assets",
    label: "Corva",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <LinkIcon onClick={() => {
          if(value !== undefined)
            value.forEach(asset => {window.open( corvaURL + '/assets/' + asset)});
        }} />
      );
   }}},
   {
    name: "extract",
    label: "Extracted Values",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <TextFieldIcon onClick={() => {
          if(value !== undefined)
            this.triggerParsedData(true,value)
        }}/>
      );
   }}},
   {
    name: "email",
    label: "Email",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <EmailIcon onClick={() => {
          if(value !== undefined)
            this.triggerParsedData(true,value)
        }}/>
      );
   }}},
   {
    name: "validation",
    label: "Comment",
    options: {
     filter: false,
     sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <Badge badgeContent={value.comments.filter((value) => { return value.type === "comment" | value.type === "important" }).length} color="secondary">
          <CommentIcon onClick={() => {
            if(value !== undefined)
              this.triggerComments(true,value,tableMeta.rowData[0])
            }}/>
        </Badge>
      );
   }}},
 ];
}
    constructor(props) {
      super(props);
      this.state = {dialogOpen : false, dialogData : {}, commentModalOpen : false, commentModalData : {}, parsedModalOpen : false, parsedModalData : {},
      columns: this.getColumns([""]), tableState: []};
    }
    render () {
      
      const options = {
        selectableRows: 'none',
        rowsPerPage: 300,
        onTableChange: (_, tableState) => {
          this.setState({tableState})
        },
      };

      return (
        <ThemeProvider theme={defaultMaterialTheme}>
      <Dialog onClose={() => {this.triggerDialog(false,{})}} aria-labelledby="simple-dialog-title" open={this.state.dialogOpen}>
      <DialogTitle>{this.state.dialogData.title ? this.state.dialogData.title : ''}</DialogTitle>
      <List>
          <ListItem button onClick={async () => {
            var validation = this.state.dialogData.data;
            validation[this.state.dialogData.key] = 'manual-verify';
            validation.comments.push({type: 'log', name: this.getCookie('name'), picture: this.getCookie('picture'), message: `Updated ${this.state.dialogData.key}`, date: new Date()})
            await axios.post( stitchURL + '/UPDATE?collection=parsed_report',{ id: this.state.recordID, key: 'validation', query: validation });
            await this.triggerDialog(false,{})
          }}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor : 'rgb(3, 155, 229)'}}>
              <ManualVerifyIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Manual Pass"} style={{color : 'white'}}/>
          </ListItem>
          <ListItem button onClick={async () => {
            var validation = this.state.dialogData.data;
            validation[this.state.dialogData.key] = 'spudder-rig';
            validation.comments.push({type: 'log', name: this.getCookie('name'), picture: this.getCookie('picture'), message: `Updated ${this.state.dialogData.key}`, date: new Date()})
            await axios.post( stitchURL + '/UPDATE?collection=parsed_report',{ id: this.state.recordID, key: 'validation', query: validation });
            await this.triggerDialog(false,{})
          }}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor : '#FF8C42'}}>
              <SpudderIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Spudder Rig"} style={{color : 'white'}}/>
          </ListItem>
          <ListItem button onClick={async () => {
            var validation = this.state.dialogData.data;
            validation[this.state.dialogData.key] = 'new-well';
            validation.comments.push({type: 'log', name: this.getCookie('name'), picture: this.getCookie('picture'), message: `Updated ${this.state.dialogData.key}`, date: new Date()})
            await axios.post( stitchURL + '/UPDATE?collection=parsed_report',{ id: this.state.recordID, key: 'validation', query: validation });
            await this.triggerDialog(false,{})
          }}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor : '#7D5BA6'}}>
              <AddIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"New Well"} style={{color : 'white'}}/>
          </ListItem>
          <ListItem button onClick={async () => {
            var validation = this.state.dialogData.data;
            validation[this.state.dialogData.key] = 'fail';
            validation.comments.push({type: 'log', name: this.getCookie('name'), picture: this.getCookie('picture'), message: `Updated ${this.state.dialogData.key}`, date: new Date()})
            await axios.post( stitchURL + '/UPDATE?collection=parsed_report',{ id: this.state.recordID, key: 'validation', query: validation });
            await this.triggerDialog(false,{})
          }}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor : 'rgb(255, 0, 0)'}}>
              <ErrorIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Fail"} style={{color : 'white'}}/>
          </ListItem>
          <ListItem button disabled={this.state.dialogData.data ? this.state.dialogData.data.unfixable || this.state.dialogData.data.status === 'success' : true} onClick={async () => {
            await axios.post( stitchURL + '/CORVA?action=reValidate', {id: this.state.recordID, action : "rerun", forceRerun : false });
            await this.triggerDialog(false,{})
          }}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor : '#73BFB8'}}>
              <ReRun/>
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Rerun"} style={{color : 'white'}}/>
          </ListItem>
          <ListItem button disabled={this.state.dialogData.data ? this.state.dialogData.data.unfixable ||  this.state.dialogData.data.errorMessages === 'No assets found with API number' || this.state.dialogData.data.status === 'success' : true} onClick={async () => {
            await axios.post( stitchURL + '/CORVA?action=reValidate', {id: this.state.recordID, action : "rerun", forceRerun : true });
            await this.triggerDialog(false,{})
          }}>
          <ListItemAvatar>
            <Avatar style={{backgroundColor : '#FF6663'}}>
              <ForceRerun/>
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Force ReRun"} style={{color : 'white'}}/>
          </ListItem>
      </List>
    </Dialog>
    <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.commentModalOpen}
        onClose={() => {this.triggerComments(false,{},'')}}
      >
        <Comments data={this.state.commentModalData} id={this.state.recordID}></Comments>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.parsedModalOpen}
        onClose={() => {this.triggerParsedData(false,{})}}
      >
        <ExtractedData data={this.state.parsedModalData}></ExtractedData>
      </Modal>
          <MUIDataTable
            title={"Surveys"}
            data={this.state.tableState ? this.props.tableData : this.state.tableState.displayData}
            columns={this.state.columns}
            options={options}
          />
        </ThemeProvider>
      )
    }

}
export default Table;

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
        main: "#333333",
    },
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        backgroundColor: "#333333",
        color: 'white'
      },
    },
    MuiList: {
      root: {
        backgroundColor: "#333333",
        color: 'white'
      },
    },
    MUIDataTable: {
      root: {
        backgroundColor: "#333333"
      },
      paper: {
        boxShadow: "none"
      }
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: orange[500],
        color : 'white'
      }
    },
    MUIDataTablePagination: {
      root: {
        backgroundColor: orange[500],
        borderWidth : 0,
        color : 'white'
      }
    }
  }
});