import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { JsonToTable } from "react-json-to-table";

class Comments extends React.Component {
  
  render() {
    return (
      <List
        subheader={
          <ListSubheader>
            <Typography variant="h4" style={{ color: "white", padding: "10px" }}>
              Extracted Values
            </Typography>
          </ListSubheader>
        }
        style={{
          textAlign: "center",
          color: 'black',
          fontSize: '40pt',
          backgroundColor: '#999'
        }}
      >
        <Divider style={{ backgroundColor: blueGrey[700] }} component="li" />
        <JsonToTable json={this.props.data} />
      </List>
    );
  }
}

export default Comments;
