import React from 'react'

export default function successIcon(props) {
    return (
        <svg width="30px" height="30px" viewBox="0 0 58 58" fill={props.fill} version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="toffee">
            <path d="M54.589,18.43 C54.61,18.415 57.233,17.634 57.07,15.734 C56.998,14.899 56.473,13.799 55.575,13.928 C54.833,14.034 53.966,13.167 54.072,12.425 L54.175,11.706 C54.281,10.964 53.414,10.097 52.672,10.203 L51.954,10.306 C51.212,10.412 50.345,9.545 50.451,8.803 L50.554,8.085 C50.66,7.343 49.793,6.476 49.051,6.582 L48.332,6.685 C47.59,6.791 46.723,5.924 46.829,5.182 L46.932,4.464 C47.038,3.722 46.171,2.855 45.429,2.961 L44.71,3.062 C43.968,3.168 43.101,2.301 43.207,1.559 L43.225,1.433 C43.347,0.578 42.301,0.08 41.505,0.009 C39.368,-0.18 38.706,2.515 38.705,2.545 C38.705,2.545 35.252,8.325 34.358,17.866 L39.59,23.098 C39.59,23.099 48.174,23.039 54.589,18.43 Z" id="Path" fill={props.fill}></path>
            <path d="M2.502,38.661 C2.481,38.676 -0.142,39.457 0.021,41.357 C0.093,42.192 0.618,43.292 1.516,43.163 C2.258,43.057 3.125,43.924 3.019,44.666 L2.916,45.385 C2.81,46.127 3.677,46.994 4.419,46.888 L5.137,46.785 C5.879,46.679 6.746,47.546 6.64,48.288 L6.537,49.006 C6.431,49.748 7.298,50.615 8.04,50.509 L8.759,50.406 C9.501,50.3 10.368,51.167 10.262,51.909 L10.159,52.627 C10.053,53.369 10.92,54.236 11.662,54.13 L12.381,54.027 C13.123,53.921 13.99,54.788 13.884,55.53 L13.866,55.656 C13.744,56.511 14.79,57.009 15.586,57.08 C17.723,57.269 18.385,54.574 18.386,54.544 C18.386,54.544 21.839,48.764 22.733,39.223 L17.501,33.991 C17.501,33.992 8.917,34.052 2.502,38.661 Z" id="Path" fill={props.fill}></path>
            <path d="M42.138,31.304 L31.304,42.138 C29.748,43.694 27.203,43.694 25.647,42.138 L14.813,31.304 C13.257,29.748 13.257,27.203 14.813,25.647 L25.647,14.813 C27.203,13.257 29.748,13.257 31.304,14.813 L42.138,25.647 C43.694,27.203 43.694,29.749 42.138,31.304 Z" id="Path" fill={props.fill}></path>
        </g>
    </g>
</svg>

    )
}
