import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import SuccessIcon from '../icons/successIcon';
import ManualVerifyIcon from '@material-ui/icons/DoneAll';
import FailIcon from '../icons/failIcon';
import WarningIcon from '@material-ui/icons/Warning';
import SpudderIcon from '@material-ui/icons/Opacity'
import BlockIcon from '@material-ui/icons/Block';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class Welcome extends React.Component {

    render () {
      return (
        <Grid container alignContent="center" spacing={0}>
      <Grid item xs={12}  sm={6} md={6} lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : 'white', color : 'black'}}>
                All
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                All
            </Typography>
            <Typography variant="h2" style={{color : 'white'}}>
               {this.props.tableData.length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>

      <Grid item xs={12} md={6}   sm={6} lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : 'rgb(0, 128, 0)'}}>
            <SuccessIcon fill="#fff" />
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                Success
            </Typography>
            <Typography variant="h2" style={{color : 'rgb(0, 128, 0)'}}>
                {this.props.tableData.filter((record) => { return record.validation.status === "success"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>

      <Grid item xs={12}  md={6}   sm={6} lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : 'rgb(255, 0, 0)'}}>
              <FailIcon fill="#fff"/>
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                Fail
            </Typography>
            <Typography variant="h2" style={{color : 'rgb(255, 0, 0)'}}>
            {this.props.tableData.filter((record) => { return record.validation.status === "fail"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>

      <Grid item xs={12}  md={6}   sm={6} lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : 'rgb(255, 165, 0)'}}>
                <WarningIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                Warning
            </Typography>
            <Typography variant="h2" style={{color : 'rgb(255, 165, 0)'}}>
            {this.props.tableData.filter((record) => { return record.validation.status === "warning"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>

      <Grid item xs={12}  md={6}  sm={6}  lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : 'rgb(3, 155, 229)'}}>
                <ManualVerifyIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                Manual Pass
            </Typography>
            <Typography variant="h2" style={{color : 'rgb(3, 155, 229)'}}>
            {this.props.tableData.filter((record) => { return record.validation.status === "manual-verify"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>
      <Grid item xs={12}  md={6}  sm={6}  lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : '#FF8C42'}}>
                <SpudderIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
              Spudder Rig
            </Typography>
            <Typography variant="h2" style={{color : '#FF8C42'}}>
            {this.props.tableData.filter((record) => { return record.validation.status === "spudder-rig"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>
      <Grid item xs={12}  md={6}  sm={6}  lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : '#73BFB8'}}>
                <BlockIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                Not Found
            </Typography>
            <Typography variant="h2" style={{color : '#73BFB8'}}>
            {this.props.tableData.filter((record) => { return record.validation.status === "not-found"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>
      <Grid item xs={12}  md={6}  sm={6}  lg={3} style={{textAlign : "center"}}>
      <ListItem>
            <ListItemAvatar>
            <Avatar style={{backgroundColor : '#7D5BA6'}}>
                <AddIcon/> 
            </Avatar>
            </ListItemAvatar>
            <ListItemText>
            <Typography variant="h6" style={{color : 'white'}}>
                New Wells
            </Typography>
            <Typography variant="h2" style={{color : '#7D5BA6'}}>
            {this.props.tableData.filter((record) => { return record.validation.status === "new-well"}).length}
              </Typography>      
            </ListItemText>
          </ListItem>
      </Grid>
  </Grid>
      )
    }

}

export default Welcome;
