import React, {Component} from "react";
import CreatableSelect from 'react-select/creatable';
import Typography from "@material-ui/core/Typography";

class Input extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(items){
    await this.props.inputChange({[this.props.inputKey] : items === null ? [] : items.map(item => {return item.value})});
  };

  render() {
    return (
        <Typography variant="h6" style={{ color: "white", width : '100%', block: 'inline', float : 'left'}}>
          {this.props.name}
          <div style={{color : 'white'}}>
        <CreatableSelect
          isMulti
          isClearable
          placeholder="Type ..."
          value={this.props.data}
          onChange={this.handleChange}
          defaultValue={this.props.data}
          name="color"
          options={this.props.data}
        />
        </div>
        </Typography>
    );
  }
}

export default Input;

