import React, {Component} from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Checkbox from '../components/form/checkbox';
import Input from '../components/form/input';
import MultipleInputs from '../components/form/multiple-input';
import Select from '../components/form/select';
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from "@material-ui/core/IconButton";
import { stitchURL } from '../assets/config.json'
class EmailTemplates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email_templates : [],
      companies : [],
      multiPDF : false,
      bulkPDF : false,
    };

    this.axios = axios.create({
      baseURL: stitchURL,
      timeout: 1000,
      headers: {}
    });

    this.inputChange = this.inputChange.bind(this);
  }

  async inputChange(value) {
    await this.setState(value)
  }

  postData(query){
    const modifedQuery = query;
    if(this.state.multiPDF){
      modifedQuery.multiPDF = true;
    }
    if(this.state.bulkPDF){
      modifedQuery.bulkPDF = {keyword : this.state.bulk_pdf_keyword};
    }
    this.axios.post('/POST?collection=email_template',modifedQuery)
    .then(function (response) {

    })
    .catch(function (error) {

    });
  }

  componentDidMount() {
    this.axios.get('/GET?collection=company').then(response => {
      this.setState({companies : response.data})});
    this.axios.get('/GET?collection=email_template').then(response => {
      this.setState({email_templates : response.data})});
  }
  render() {

    const columns = [
      {
        name: "_id",
        options: {
          filter: false,
          sort: false,
        }
      }, 
      {
       name: "name",
       label: "Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "to",
        label: "To",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "from",
        label: "From",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "subject",
        label: "Subject",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "body",
        label: "Body",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "keyword",
        label: "Keyword",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "Actions",
        label: "Actions",
        options: {
         filter: true,
         sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
            <Delete style={{marginLeft : '5px'}}></Delete><Edit style={{marginRight : '5px'}}></Edit>
            </div>
          );
       }}},   
     ];
    
    const options = {
      selectableRows: 'none',
      customToolbar: () => {
        return (
        <IconButton>
          <Add />
        </IconButton>);
      }
    };
    return (
      <div>
        <MUIDataTable
            title={"Email Templates"}
            data={this.state.email_templates}
            columns={columns}
            options={options}
          />
        <Typography variant="h4" style={{ color: "white", padding: "8px" }}>
          Email Template
        </Typography>
        <FormGroup style={{width: '35%'}}>
        <Input name="Email Template Name" inputKey="name" inputChange={this.inputChange} />
        <MultipleInputs name="Email Template To Email Addresses" inputKey="to" inputChange={this.inputChange} />
        <MultipleInputs name="Email Template From Email Addresses" inputKey="from" inputChange={this.inputChange} />
        <MultipleInputs name="Email Template Subjects in Email" inputKey="subject" inputChange={this.inputChange} />
        <MultipleInputs name="Email Template Body in Email" inputKey="body" inputChange={this.inputChange} />
        <MultipleInputs name="Email Template Keyword in Email" inputKey="keyword" inputChange={this.inputChange} />
        <Checkbox name="Extract"  inputKey="extract" inputChange={this.inputChange} />
        <Checkbox name="Is there multiple PDFs in this Email"  inputKey="multiPDF" inputChange={this.inputChange} />
        <Checkbox name="Is there a Bulk PDF in this Email"  inputKey="bulkPDF" inputChange={this.inputChange} />
        {this.state.bulkPDF ? <Input name="Keyword To Split the PDF" inputKey="bulk_pdf_keyword" inputChange={this.inputChange} />  : ''}
        <Select name="Company" inputKey="company_id" inputChange={this.inputChange} data={this.state.companies.map(company => {return {value : company._id, label : company.name}})} />
        <br />
        <Button variant="contained" color="secondary" onClick={() => {
          this.postData({ to: this.state.to, name: this.state.name, from: this.state.from, subject: this.state.subject, body: this.state.body, keyword: this.state.keyword, extract: this.state.extract, company_id : this.state.company_id })
          }}>Post</Button>
        </FormGroup>
      </div>
    );
  }
}

export default EmailTemplates;