import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DDR from "./DDR";
import Surveys from "./Surveys";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CorvaPumpkin from "./assets/CorvaPumpkin.svg"
import StitchModule from "./stitch";
import DocumentMeta from 'react-document-meta';
import { parsebotENV } from './assets/config.json'
import CompanyCRUD from './CRUD/company'
import EmailTemplateCRUD from './CRUD/email_template'
import RecordTypeCRUD from './CRUD/record_type'
import RecordTypeTemplateCRUD from './CRUD/record_type_template'
import RigCRUD from './CRUD/rig'
import TemplateCRUD from './CRUD/template'

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: false
    };
    this.Stitch = new StitchModule();
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async logoutStitch() {
    this.Stitch.logout()
  }
  render() {

    const meta = {
      title: 'Parsebot ' + parsebotENV,
      meta: {
        charset: 'utf-8',
        name: {}
      }
    };

    return (
      <Router>
        <DocumentMeta {...meta}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppBar
            position="static"
            justify="space-between"
            style={{ boxShadow: "none", backgroundColor: "#333333" }}
          >
            <Toolbar>
              <Grid justify="space-between" container spacing={10}>
                <Grid item>
                  <Typography variant="h6">ParseBot {parsebotENV} <img alt="Pumpkin" width="30px" src={CorvaPumpkin}/></Typography>
                </Grid>
                <Grid item>
                  <Link
                    to="/"
                    style={{ textDecoration : "none", color: "white" }}
                  >
                    <Button color="inherit">DDR</Button>
                  </Link>
                  {this.getCookie("admin") !== "" && (
                  <Link
                    to="/Surveys"
                    style={{ textDecoration : "none", color: "white" }}
                  >
                    <Button color="inherit">Surveys</Button>
                  </Link>
                  )}
                  <Button
                    onClick={() => this.logoutStitch()}
                    color="inherit"
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Route path="/" exact component={DDR} />
          <Route path="/Surveys" exact component={Surveys} />
          <Route path="/EmailTemplate" component={EmailTemplateCRUD} />
          <Route path="/Company" component={CompanyCRUD} />
          <Route path="/RecordType" component={RecordTypeCRUD} />
          <Route path="/RecordTypeTemplate" component={RecordTypeTemplateCRUD} />
          <Route path="/Rig" component={RigCRUD} />
          <Route path="/Template" component={TemplateCRUD} />
          {this.getCookie("admin") !== "" && (
          <div>
          <Link to="/EmailTemplate" style={{ textDecoration: "none", color: "white", fontSize: '10px' }}> EmailTemplate </Link>
          <Link to="/Company" style={{ textDecoration: "none", color: "white", fontSize: '10px' }}> Company </Link>
          <Link to="/RecordType" style={{ textDecoration: "none", color: "white", fontSize: '10px' }}> RecordType </Link>
          <Link to="/RecordTypeTemplate" style={{ textDecoration: "none", color: "white", fontSize: '10px' }}> RecordTypeTemplate </Link>
          <Link to="/Rig" style={{ textDecoration: "none", color: "white", fontSize: '10px' }}> Rig </Link>
          <Link to="/Template" style={{ textDecoration: "none", color: "white", fontSize: '10px' }}> Template </Link>
          </div>
    )}
        </MuiPickersUtilsProvider>
        </DocumentMeta>
      </Router>
    );
  }
}

export default AppRouter;
