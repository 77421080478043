import React, {Component} from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Input from '../components/form/input'
import MultipleInputs from '../components/form/multiple-input'
import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add'
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from "@material-ui/core/IconButton";
import { stitchURL } from '../assets/config.json'

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies : []
    };

    this.axios = axios.create({
      baseURL: stitchURL,
      timeout: 1000,
      headers: {}
    });

    this.inputChange = this.inputChange.bind(this);
  }

  async inputChange(value) {
    await this.setState(value)
  }

  postData(query){
    this.axios.post('/POST?collection=company', query)
    .then(function (response) {

    })
    .catch(function (error) {

    });
  }

  componentDidMount() {
    
    this.axios.get('/GET?collection=company').then(response => {
      this.setState({companies : response.data})});
  }
  render() {

    const columns = [
      {
        name: "_id",
        options: {
          filter: false,
          sort: false,
        }
      }, 
      {
       name: "name",
       label: "Company Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "to",
        label: "To",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "from",
        label: "From",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "subject",
        label: "Subject",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "body",
        label: "Body",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "keyword",
        label: "Keyword",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "validation.npt-status",
        label: "NPT",
        options: {
         filter: true,
         sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
            <Delete style={{marginLeft : '5px'}}></Delete><Edit style={{marginRight : '5px'}}></Edit>
            </div>
          );
       }}},   
     ];
    
    const options = {
      selectableRows: 'none',
      customToolbar: () => {
        return (
        <IconButton>
          <Add />
        </IconButton>);
      }
    };
    return (
      <div>
        <MUIDataTable
            title={"Companies"}
            data={this.state.companies}
            columns={columns}
            options={options}
          />
        <Typography variant="h4" style={{ color: "white", padding: "8px" }}>
          Company
        </Typography>
        <FormGroup style={{width: '35%'}}>
          <Input name="Company Name" inputKey="name" inputChange={this.inputChange} />
          <MultipleInputs name="Company To Email Addresses" inputKey="to" inputChange={this.inputChange} />
          <MultipleInputs name="Company From Email Addresses" inputKey="from" inputChange={this.inputChange} />
          <MultipleInputs name="Company Subjects in Email" inputKey="subject" inputChange={this.inputChange} />
          <MultipleInputs name="Company Body in Email" inputKey="body" inputChange={this.inputChange} />
          <MultipleInputs name="Company Keyword in Email" inputKey="keyword" inputChange={this.inputChange} />
          <br />
          <Button variant="contained" color="secondary" onClick={() => {this.postData({ to: this.state.to, name: this.state.name, from: this.state.from, subject: this.state.subject, body: this.state.body, keyword: this.state.keyword })}}>Post</Button>
        </FormGroup>
      </div>
    );
  }
}

export default Company;