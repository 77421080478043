import React, {Component} from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Input from '../components/form/input';
import MultipleInputs from '../components/form/multiple-input';
import MultipleSelect from '../components/form/multiple-select';
import Select from '../components/form/select';
import Button from "@material-ui/core/Button";
import MUIDataTable from "mui-datatables";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from "@material-ui/core/IconButton";
import { stitchURL } from '../assets/config.json'

class RecordType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record_types : [],
      rigs : [],
      templates : [],
      companies : [],
      company_id : '',
    };

    this.axios = axios.create({
      baseURL: stitchURL,
      headers: {}
    });

    this.inputChange = this.inputChange.bind(this);
  }

  async inputChange(value) {
    await this.setState(value)
    await this.setState({rig_id : this.state.rigs.filter(rig  => {return rig.company_id === this.state.company_id}).map(rig => {return rig.value})});
  }

  postData(query){
    this.axios.post('/POST?collection=record_type', query)
    .then(function (response) {

    })
    .catch(function (error) {

    });
  }

  componentDidMount() {
    this.axios.get('/GET?collection=company').then(response => {
      this.setState({companies : response.data})});
    this.axios.get('/GET?collection=record_type').then(response => {
      this.setState({record_types : response.data})});
      this.axios.get('/GET?collection=rig').then(response => {
        this.setState({rigs : response.data.map(rig => {return {value : rig._id, company_id: rig.company_id,label : rig.name.join(' - ')}})})});
  }
  render() {

    const columns = [
      {
        name: "_id",
        options: {
          filter: false,
          sort: false,
        }
      }, 
      {
       name: "name",
       label: "Company Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "to",
        label: "To",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "from",
        label: "From",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "subject",
        label: "Subject",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "body",
        label: "Body",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "keyword",
        label: "Keyword",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "type",
        label: "Type",
        options: {
         filter: true,
         sort: true,
       }},
       {
        name: "validation.npt-status",
        label: "Actions",
        options: {
         filter: true,
         sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
            <Delete style={{marginLeft : '5px'}}></Delete><Edit style={{marginRight : '5px'}}></Edit>
            </div>
          );
       }}},   
     ];
    
    const options = {
      selectableRows: 'none',
      customToolbar: () => {
        return (
        <IconButton>
          <Add />
        </IconButton>);
      }
    };
    return (
      <div>
        <MUIDataTable
            title={"Record Type"}
            data={this.state.record_types}
            columns={columns}
            options={options}
          />
        <Typography variant="h4" style={{ color: "white", padding: "8px" }}>
          Record Type
        </Typography>
        <FormGroup style={{width: '35%'}}>
        <Input name="Rig Name" inputKey="name" inputChange={this.inputChange} />
        <MultipleInputs name="Rig Subjects in Email" inputKey="subject" inputChange={this.inputChange} />
        <MultipleInputs name="Rig Body in Email" inputKey="body" inputChange={this.inputChange} />
        <MultipleInputs name="Rig Keyword in Attachment" inputKey="keyword" inputChange={this.inputChange} />
        <Select name="Company" inputKey="company_id" inputChange={this.inputChange} data={this.state.companies.map(company => {return {value : company._id, label : company.name}})}/>
        <Select name="Record Type" inputKey="type" inputChange={this.inputChange} data={[{ label : "Survey", value : "survey"},{ label: "DDR", value : "ddr"}]}/>
        <MultipleSelect name="Rig" inputKey="rig_id" inputChange={this.inputChange} data={this.state.rigs.filter(rig  => {return rig.company_id === this.state.company_id})} />
        <br />
        <Button variant="contained" color="secondary" onClick={() => {this.postData({ name: this.state.name, subject: this.state.subject, type: this.state.type, body: this.state.body, attachment: this.state.attachment, template_id : this.state.template_id, rig_id : this.state.rig_id })}}>Post</Button>
        </FormGroup>
      </div>
    );
  }
}

export default RecordType;