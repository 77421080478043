import React, {Component} from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueChecked : false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange() {
    await this.state.valueChecked ? this.setState({valueChecked : false}) : this.setState({valueChecked : true});
    await this.props.inputChange({[this.props.inputKey] : this.state.valueChecked});
  };

  render() {
    return (
        <Typography variant="h6" style={{ color: "white", padding: "8px", block: 'inline', float : 'left'}}>
          {this.props.name}
        <Paper style={{ display: "flex", alignItems: "center"}}>
        <Checkbox
        checked={this.state.valueChecked}
        onChange={() => this.handleChange()}
        value="checkedA"
        inputProps={{
          'aria-label': 'primary checkbox',
        }}
      />
        </Paper>
        </Typography>
    );
  }
}

export default Input;

