import { Stitch, GoogleRedirectCredential } from "mongodb-stitch-browser-sdk";
import axios from "axios";
import { stitchAppID } from "./assets/config.json"

class StitchModule {
    constructor(){
        const appId = stitchAppID;

        const client = Stitch.hasAppClient(appId)
          ? Stitch.getAppClient(appId)
          : Stitch.initializeAppClient(appId);
    
        if (client.auth.hasRedirectResult()) {
            client.auth.handleRedirectResult().catch(console.error);

        }
    
        if (client.auth.isLoggedIn) {
          const currentUser = client.auth.user;
          axios.defaults.headers.common = {
              "Authorization": currentUser.id,
          };
          console.log(currentUser);
          document.cookie = "user_id=" + currentUser.id;
          document.cookie = "name=" + currentUser.profile.name;
          document.cookie = "picture=" + currentUser.profile.pictureUrl;
        } else {
          document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
          const credential = new GoogleRedirectCredential();
          client.auth.loginWithRedirect(credential);
        }
    }

    async logout() {
      const appId = stitchAppID;

    const client = Stitch.hasAppClient(appId)
      ? Stitch.getAppClient(appId)
      : Stitch.initializeAppClient(appId);
    await document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    await client.auth.logout();
    await window.location.reload();
    }
}
 
export default StitchModule;