import React, {Component} from "react";
import Select from 'react-select';
import Typography from "@material-ui/core/Typography";

class Input extends Component {
  constructor(props) {
    super(props);
    
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(item){
    await this.props.inputChange({[this.props.inputKey] : item.value});
  };

  render() {
    return (
        <Typography variant="h6" style={{ color: "white", padding: "8px", block: 'inline', float : 'left'}}>
          {this.props.name}
          <div style={{color : 'white'}}>
        <Select
          isClearable
          onChange={this.handleChange}
          defaultValue={[]}
          options={this.props.data}
        />
        </div>
        </Typography>
    );
  }
}

export default Input;



