import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "./components/table/ddr_table";
import { DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Counter from "./components/counter/ddr_counter";
import isMobile from "is-mobile";
import Moment from 'moment'
import axios from "axios";
import { stitchURL } from './assets/config.json'


class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      date: new Date(),
      allCount : 0,
      successCount : 0,
      failCount : 0,
      warningCount : 0,
      manualPassCount : 0,
      parsed_report : [],
      currentUser: {}
    };
  }

  async componentDidMount(){

    this.setState({date : Moment(new Date())});
    axios.get( stitchURL + '/GET?collection=parsed_report&query=' + JSON.stringify({'date': {'$gte': Moment(new Date()).startOf('day').unix(), '$lt': Moment(new Date()).endOf('day').unix()}, 'record_type.type' : 'ddr'})).then(response => {this.setState({parsed_report : response.data})});    
  }

  render() {
    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <Grid container alignContent="center" spacing={0}>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <Paper
              style={{
                backgroundColor: "#333333",
                width: "100%",
                height: "100%",
                borderRadius: 0,
                boxShadow: "none"
              }}
            >
              <DatePicker
                autoOk
                orientation={isMobile() ? "portrait" : "landscape"}
                variant="static"
                openTo="date"
                value={this.state.date}
                onChange={e => {
                  this.setState({date : Moment(e._d)});
                  axios.get( stitchURL + '/GET?collection=parsed_report&query=' + JSON.stringify({'date': {'$gte': Moment(e._d).startOf('day').unix(), '$lt': Moment(e._d).endOf('day').unix()}, 'record_type.type' : 'ddr'})).then(response => {this.setState({parsed_report : response.data})});
                }}
              />
            </Paper>
          </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <Paper
              style={{
                backgroundColor: "#333333",
                width: "100%",
                height: "100%",
                borderRadius: 0,
                boxShadow: "none"
              }}
            >
              <Counter tableData={this.state.parsed_report} />
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Paper>
              <Table tableData={this.state.parsed_report} />
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default Homepage;

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#333333"
    },
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#333333"
        }
      },
      MuiPickersBasePicker: {
        backgroundColor: "#333333"
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "#333333"
        }
      },
      MuiPickersDay: {
        day: {
          color: "#333333"
        },
        daySelected: {
          backgroundColor: "#333333"
        },
        dayDisabled: {
          color: "#333333"
        },
        current: {
          color: "#333333"
        }
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#333333",
          backgroundColor: "#333333"
        }
      }
    }
  }
});
