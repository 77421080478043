import React, {Component} from "react";
import Input from '@material-ui/core/Input';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(value) {
    await this.props.inputChange({[this.props.inputKey] : value});
  };
  
  render() {
    return (
        <Typography variant="h6" style={{ color: "white", block: 'inline', float : 'left'}}>
          {this.props.name}
        <Paper style={{ display: "flex", width: '100%', alignItems: "center"}}>
        <Input
            fullWidth
            style={{ height: '44px',  width: '100%' }}
            placeholder='Type ...'
            onChange={e => {
                this.handleChange(e.target.value);
            }}
          />
        </Paper>
        </Typography>
    );
  }
}

export default InputField;

