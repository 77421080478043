import React, {Component} from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Input from '../components/form/input'
import { Button } from "@material-ui/core";
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import MUIDataTable from "mui-datatables";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add'
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from "@material-ui/core/IconButton";
import { stitchURL } from '../assets/config.json'
import ReactJson from 'react-json-view'

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates : []
    };

    this.axios = axios.create({
      baseURL: stitchURL,
      timeout: 1000,
      headers: {}
    });

    this.inputChange = this.inputChange.bind(this);
  }

  async inputChange(value) {
    await this.setState(value)

  }

  postData(query){
    this.axios.post('/POST?collection=template',query)
    .then(function (response) {

    })
    .catch(function (error) {

    });
  }

  componentDidMount() {
    
    this.axios.get('/GET?collection=template').then(response => {
      this.setState({templates : response.data.map(template => {return {_id: template._id, name : template.name, template : JSON.stringify(template)}})})});
  }
  render() {

    const columns = [
      {
        name: "_id",
        options: {
          filter: false,
          sort: false,
        }
      }, 
      {
       name: "name",
       label: "Name",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "template",
        label: "Template",
        options: {
         filter: true,
         sort: true,
         customBodyRender: (value, tableMeta, updateValue) => {
           return (
            <ReactJson collapsed src={JSON.parse(value)} />
           );
         }
        }
       },
       {
        name: "Actions",
        label: "Actions",
        options: {
         filter: true,
         sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
            <Delete style={{marginLeft : '5px'}}></Delete><Edit style={{marginRight : '5px'}}></Edit>
            </div>
          );
       }}},   
     ];
    
    const options = {
      selectableRows: 'none',
      customToolbar: () => {
        return (
        <IconButton>
          <Add />
        </IconButton>);
      }
    };
    return (
      <div>
        <MUIDataTable
            title={"Templates"}
            data={this.state.templates}
            columns={columns}
            options={options}
          />
        <Typography variant="h4" style={{ color: "white", padding: "8px" }}>
          Template
        </Typography>
        <FormGroup>
        <Input name="Template Name" inputKey="name" inputChange={this.inputChange} />
        <JSONInput
          locale      = { locale }
          height      = '550px'
          onChange = {(e) => {if(e.json){this.inputChange({ 'template' : JSON.parse(e.json)})}}}
        />
        <br />
        <Button variant="contained" color="secondary" onClick={() => {this.postData({name : this.state.name, template : this.state.template})}}>Post</Button>  
        </FormGroup>  
        <br />
      </div>
    );
  }
}

export default Template;